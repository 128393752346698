.fund-table {
  border: 1px solid $primary;
  border-radius: $border-radius;
  overflow: hidden;
  > table {
    width: 100%;
  }
  .fund-item {
    border-bottom: 1px solid $primary;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    > .title {
      background-color: #d4eaf6;
      padding: 15px;
      color: $primary;
      @include media-breakpoint-up(sm) {
        width: 25%;
        display: flex;
        align-items: center;
      }
    }
    > .text {
      padding: 15px;
      @include media-breakpoint-up(sm) {
        width: 75%;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
