.thirdHeader {
  padding: 20px 6vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    display: inline-block;
    padding-bottom: 4px;
    border-bottom: 2px solid;
    color: $primary;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
}