.project-title {
  margin-bottom: 30px;
  font-size: 24px;
  @include media-breakpoint-up(sm) {
    font-size: 30px;
  }
}
.projectHeader {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  position: relative;
  > .label {
    display: inline-block;
    padding: 3px 10px;
    background-color: $secondary;
    color: $white;
    font-size: 12px;
    min-width: 90px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  > .thumbnail {
    flex-basis: 100%;
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
  }
  > .cont {
    flex-basis: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include media-breakpoint-up(sm) {
      flex: 1;
      align-self: center;
    }
    .title {
    }
    .data {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .data-title {
        color: $secondary;
        font-size: 15px;
        margin: 0;
        font-weight: bold;
      }
      .data-data {
        font-size: 15px;
        font-weight: bold;
        strong {
          font-size: 200%;
          font-weight: 700;
          line-height: 1;
        }
        &.end-size {
          strong {
            font-size: 24px;
          }
        }
      }
      .progress {
      }
      .text {
        margin: 0;
      }
    }
  }
}
.project-cta {
  margin-top: 30px;
}
