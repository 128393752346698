.secondHeader {
  // margin-top: $headerHeightSM;
  position: relative;
  @include media-breakpoint-up(sm) {
    // margin-top: $headerHeightLG;
  }
  .bg {
    width: 100%;
    height: 300px;
    padding-left: 12vw;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      height: 450px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius 0 0 $border-radius;
    }
  }
  .cont {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 6vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .inner {
      background-color: $white;
      padding: 2rem;
      padding-left: 0;
      border-radius: $border-radius;
      @include media-breakpoint-up(sm) {
        padding: 2.5rem;
      }
      .title {
        font-size: 24px;
        line-height: 1.25;
        font-weight: bold;
        color: $primary;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 36px;
          letter-spacing: 0.2em;
        }
        @include media-breakpoint-up(lg) {
          font-size: 45px;
        }
      }
      .title-en {
        font-size: 12px;
        font-weight: bold;
        color: $primary;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin: 0;
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
          margin-top: 15px;
        }
        &::before {
          width: 20px;
          height: 2px;
          background-color: $primary;
          content: "";
        }
      }
    }
  }
}
