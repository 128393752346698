.hero {
  width: 100%;
  height: calc(446 / 375 * 100vw);
  position: relative;

  @include media-breakpoint-up(sm) {
    height: calc(600 / 820 * 100vw);
  }

  @include media-breakpoint-up(lg) {
    height: calc(600 / 1280 * 100vw);
  }

  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      width: calc(600 / 820 * 100vw);
    }

    @include media-breakpoint-up(lg) {
      width: calc(923 / 1280 * 100vw);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cont {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    color: $primary;

    @include media-breakpoint-up(sm) {
      padding: calc(60 / 820 * 100vw) calc(45 / 820 * 100vw);
    }

    @include media-breakpoint-up(lg) {
      padding: calc(80 / 1280 * 100vw) calc(52 / 1280 * 100vw);
    }

    .inner {
      .title {
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
          font-size: 15px;
        }

      }

      .catchcopy {
        color: #2896D0;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 2.4px;
        margin-bottom: 12px;

        @include media-breakpoint-up(lg) {
          font-size: 40px;
          letter-spacing: 4px;
        }
      }

      .leadcopy {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: 1.4px;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          letter-spacing: 1.8px;
          margin-bottom: 30px;
        }
      }

      .more {}
    }
  }
}