
    .meter {
      margin-bottom: 30px;
      .header {
        p {
          margin: 0;
          font-size: 12px;
          line-height: 1;
          @include media-breakpoint-up(sm) {
            font-size: 15px;
          }
          &.number {
            margin-top: 5px;
            font-size: 24px;
            font-weight: bold;
            @include media-breakpoint-up(sm) {
              font-size: 30px;
            }
            .parameter {
              font-size: 15px;
              @include media-breakpoint-up(sm) {
                font-size: 18px;
              }
            }
          }
        }
      }
      .body {
        width: 100%;
        height: 20px;
        border-radius: 10px;
        background-color: $gray-300;
        margin-top: 15px;
        .now {
          width: 40%;
          height: 20px;
          border-radius: 10px;
          background-color: $danger;
          border: 2px solid $white;
        }
      }
    }