.topLead {
  padding: 60px 0 0;
  background-color: $bg-blue;
  position: relative;
  .bgAnime {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  @include media-breakpoint-up(sm) {
    padding: 90px 0 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0 0;
  }
  .title {
    position: relative;
    z-index: 2;
    font-size: 21px;
    text-align: center;
    display: flex;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $primary;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      letter-spacing: 0.2em;
    }
    span {
      display: inline-block;
      padding-bottom: 5px;
      border-bottom: 2px solid;
    }
  }
  .bullet {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: $primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    li {
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      &::before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        content: "\f14a";
        color: $lovely;
      }
      @include media-breakpoint-up(sm) {
        font-size: 18px;
        letter-spacing: 0.2em;
      }
    }
  }
}
