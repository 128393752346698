.summary {
  .icon {
    margin-bottom: 24px;
    padding: 0 30px;
    text-align: center;
    img {
      max-height: 25vh;
      width: auto;
    }
  }
  .thumbnail {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 24px;
    border-radius: $border-radius;
    &::before {
      content: "";
      width: 100%;
      padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
    .label {
      display: inline-block;
      padding: 3px 10px;
      background-color: $secondary;
      color: $white;
      position: absolute;
      top: $border-radius;
      left: 0;
      font-size: 12px;
      min-width: 90px;
      text-align: center;
      z-index: 2;
    }
    .progress {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      border: 1px solid $white;
      border-radius: 0 0 $border-radius $border-radius;
      background-color: rgba($black, 0.5);
    }
  }
  a.thumbnail {
    display: block;
    img {
      transition: $transition-base;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .title {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
    a {
      color: $body-color;
      transition: $transition-base;
      text-decoration: none;
      &:hover {
        color: $primary;
      }
    }
  }
  .text {
    margin: 0;
    padding: 0;
    line-height: 2;
    color: $secondary;
    font-size: 13px;
    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }
  }
}
