// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: underline;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: null;

.moreLink {
  display: flex;
  gap: 10px;
  align-items: baseline;
  color: $primary;
  font-weight: bold;
  text-decoration: none;
  transition: $transition-base;
  &::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f054";
    font-weight: 100;
    transition: $transition-base;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      width: 45px;
      height: 45px;
    }
  }
  &:hover {
    color: shade-color($primary, 10%);
    &::after {
      background-color: shade-color($primary, 10%);
    }
  }
}
