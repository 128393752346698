#header {
  position: sticky;
  z-index: $zindex-fixed + 1;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeightSM;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;

  @include media-breakpoint-up(sm) {
    height: $headerHeightLG;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 30px;
    gap: 30px;
  }

  .logo {
    flex: 1;

    img {
      height: 30px;
      width: auto;

      @include media-breakpoint-up(sm) {
        height: 50px;
        width: auto;
      }
    }
  }

  .gnav {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      li {
        a {
          color: $body-color;
          text-decoration: none;
          font-size: 15px;
          font-weight: 300;
          line-height: 1;
          display: block;
        }
      }
    }
  }

  .hcta {
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      height: 30px;
      min-width: 120px;
      border-radius: 30px;

      @include media-breakpoint-up(sm) {
        font-size: 15px;
        height: 47px;
        min-width: 200px;
      }
    }
  }

  .toggleMenuBtn {
    width: $headerHeightSM;
    height: $headerHeightSM;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    @include media-breakpoint-up(sm) {
      width: $headerHeightLG;
      height: $headerHeightLG;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      width: 24px;
      height: 2px;
      background-color: $primary;
      display: block;
    }

    &.navMode {
      &::after {
        display: none;
      }
    }
  }
}