.cta {
  padding: 60px 0;
  position: relative;
  .bgWave {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .bgAnime {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  .ctaBox {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    background: rgb(40, 150, 208);
    background: linear-gradient(
      120deg,
      rgba(40, 150, 208, 1) 0%,
      rgba(224, 108, 164, 1) 100%
    );
    color: $white;
    text-align: center;
    padding: 45px 30px;
    @include media-breakpoint-up(sm) {
      padding: 45px;
    }
    @include media-breakpoint-up(lg) {
      padding: 60px;
    }
    .naname {
      position: absolute;
      width: 60px;
      @include media-breakpoint-up(sm) {
        width: 120px;
      }
      &.top {
        top: -15px;
        left: -15px;
      }
      &.bottom {
        bottom: -15px;
        right: -15px;
      }
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        font-size: 36px;
        letter-spacing: 0.2em;
      }
    }
    .text {
      font-size: 15px;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
    }
    .meter {
      margin-bottom: 30px;
      .header {
        p {
          margin: 0;
          font-size: 12px;
          line-height: 1;
          @include media-breakpoint-up(sm) {
            font-size: 15px;
          }
          &.number {
            margin-top: 5px;
            font-size: 24px;
            font-weight: bold;
            @include media-breakpoint-up(sm) {
              font-size: 30px;
            }
            .parameter {
              font-size: 15px;
              @include media-breakpoint-up(sm) {
                font-size: 18px;
              }
            }
          }
        }
      }
      .body {
        width: 100%;
        height: 20px;
        border-radius: 10px;
        background-color: $white;
        margin-top: 15px;
        .now {
          width: 40%;
          height: 20px;
          border-radius: 10px;
          background-color: $danger;
          border: 2px solid $white;
        }
      }
    }
    .comment {
      margin: 0;
      margin-top: 15px;
      font-size: 12px;
    }
  }
}
