.officer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
  }
  .sectionHeader {
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
  }
  .officer-group {
    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
    .officer-single {
      margin-bottom: 20px;
      .group-name {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 16px;
      }
      .officer-list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        gap: 8px 15px;
        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 15px;
          &::after {
            content: "/";
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
