.aboutcont2 {
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .img {
    padding-right: 20px;

    @include media-breakpoint-up(lg) {
      padding: 0;
      flex-basis: 50%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .cont {
    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
      padding: 0 60px;
      flex: 1;
    }

    .catchcopy {
      color: $primary;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 2.4px;
      margin-bottom: 24px;

      @include media-breakpoint-up(lg) {
        font-size: 30px;
        letter-spacing: 3px;
      }
    }

    .leadcopy {
      color: $primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 35px;
      letter-spacing: 1.4px;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 45px;
        letter-spacing: 1.8px;
      }
    }
  }
}