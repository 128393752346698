.donorList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 1.5em;
  li {
    white-space: nowrap;
  }
}
