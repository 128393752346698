.toggleMenu {
  background-color: rgba($white, 0.75);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 600px;
  padding: $headerHeightSM * 2 6vw;
  overflow: auto;

  @include media-breakpoint-up(sm) {
    padding: $headerHeightSM * 2 3vw;
  }

  ul.gnav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 12px;
    font-weight: bold;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }

    >li {
      >a {
        color: $body-color;
        text-decoration: none;
        padding: 0.5rem 0;
        display: block;
        transition: $transition-base;

        &:hover {
          text-decoration: none;
          color: $primary;
        }
      }

      >ul {
        margin: 0;
        padding: 0;
        padding-left: 1em;
        list-style-type: none;
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 1em;

        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }

        >li {
          >a {
            color: $body-color;
            text-decoration: none;
            // padding: 0.5rem 0;
            display: block;
            transition: $transition-base;

            &:hover {
              text-decoration: none;
              color: $primary;
            }
          }
        }
      }
    }
  }
}