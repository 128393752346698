.dataTable {
  display: table;
  width: 100%;
  border-spacing: 0 2px;
  font-size: 14px;
  > dl {
    display: table-row;
    margin: 0;
    padding: 0;
    > dt,
    > dd {
      display: table-cell;
      padding: 10px 10px;
      p,
      ul,
      ol {
        &:last-child {
          margin: 0;
        }
      }
    }
    > dt {
      color: $primary;
      background-color: rgba($color: $primary, $alpha: 0.15);
      width: 1%;
      font-weight: bold;
      white-space: nowrap;
    }
  }
  &.white {
    > dl {
      > dt {
        color: $white;
        background-color: rgba($color: $white, $alpha: 0.15);
        width: 1%;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}
