.contactArea {
  background-color: $primary;
  color: $white;
  text-align: center;
  padding: 45px 0;
  @include media-breakpoint-up(sm) {
    padding: 60px 0;
  }
  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }
  .leadText {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 30px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }
  .btn-more {
    color: $primary;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}
