.sectionHeader {
  margin-bottom: 45px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    color: $primary;
    margin: 0;

    @include media-breakpoint-up(sm) {
      font-size: 36px;
      letter-spacing: 0.2em;
    }

    @include media-breakpoint-up(lg) {
      font-size: 45px;
    }
  }

  .title-en {
    font-size: 12px;
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 0;
    margin-top: 20px;

    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }

    &::before {
      width: 20px;
      height: 2px;
      background-color: $primary;
      content: "";
    }
  }

  .sectionLead {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    margin-top: 30px;

    @include media-breakpoint-up(sm) {
      margin-top: 40px;
    }

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }

  .sectionMore {
    margin-top: 30px;

    @include media-breakpoint-up(sm) {
      margin-top: 40px;
    }

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }

  }
}