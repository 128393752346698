.bnrs {
  padding: 30px 6vw;
  background-color: $gray-100;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    li {
      a {
        color: $body-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        text-decoration: none;
        transition: $transition-base;
        &:hover {
          color: $primary;
        }
        img {
          display: block;
        }
        p {
          font-size: 10px;
          margin: 0;
        }
      }
    }
  }
}
