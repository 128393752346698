@import "custom";
@import "~bootstrap/scss/bootstrap";
$headerHeightSM: 60px;
$headerHeightLG: 90px;

html {
  font-size: 15px;
  // font-feature-settings: "palt";
  // letter-spacing: 0.08em;
}

img {
  max-width: 100%;
  height: auto;
}

#app {
  // overflow-x: hidden;
}

main {
  overflow-x: hidden;
}

[v-cloak] {
  display: none;
}

@import "components/information-bar";
@import "components/header";
@import "components/toggleMenu";
@import "components/meter";
@import "components/layout";
@import "components/hero";
@import "components/topLead";
@import "components/topAbout";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/thirdHeader";
@import "components/projectHeader";
@import "components/reportArchive";
@import "components/newsList";
@import "components/cardLink";
@import "components/cta";
@import "components/summary";
@import "components/outline";
@import "components/digest";
@import "components/article";
@import "components/eventArchive";
@import "components/anchorLinks";
@import "components/imgOverlayHeader";
@import "components/brandTable";
@import "components/dataTable";
@import "components/formTable";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/flow";
@import "components/faq";
@import "components/donorList";
@import "components/contactArea";
@import "components/aboutcont1";
@import "components/aboutcont2";
@import "components/midashi1";
@import "components/u";
@import "components/anime";
@import "components/vif";
@import "components/bnrs";
@import "components/officer";
@import "components/fund-table";
@import "components/banner";
@import "components/footer";