.banner {
  display: none;
  margin-top: 40px;
  max-width: 300px;
  @include media-breakpoint-up(md) {
    display: block;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-up(xl) {
    display: block;
  }
  &.sp {
    display: block;
    margin: 0 auto 40px;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -80px;
      display: block;
      max-width: 300px;
    }
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}
