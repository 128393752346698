// Cards

// scss-docs-start card-variables
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-title-spacer-y: $spacer * 0.5;
$card-border-width: $border-width;
$card-border-color: rgba($black, 0.125);
$card-border-radius: $border-radius;
$card-box-shadow: null;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: $card-spacer-y * 0.5;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: rgba($black, 0.03);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;
$card-img-overlay-padding: $spacer;
$card-group-margin: $grid-gutter-width * 0.5;
// scss-docs-end card-variables
