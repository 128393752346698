.digest {
  @include media-breakpoint-up(sm) {
    display: flex;
    .img {
      flex-basis: 50%;
      align-self: flex-start;
    }
    .cont {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &.reverse {
      flex-direction: row-reverse;
    }
  }
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    padding: 24px 6vw;
    .title {
      line-height: 1.5;
      margin-bottom: 20px;
    }
    .text {
      line-height: 2;
      color: $secondary;
      font-size: 13px;
      margin-bottom: 20px;
      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }
    }
    .moreLink {
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }
    }
  }
}
