.eventArchive {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .item {
    border-top: 1px solid $border-color;
    width: 100%;
    > a {
      color: $body-color;
      text-decoration: none;
      transition: $transition-base;
      display: grid;
      grid-template:
        "......... ......... ........." 15px
        "thumbnail thumbnail thumbnail"
        "......... ......... ........." 15px
        "date      ......... cont"
        "......... ......... ........." 15px
        / 66px 15px 1fr;
      @include media-breakpoint-up(sm) {
        grid-template:
          "......... ... .... ... ...." 24px
          "thumbnail ... date ... cont"
          "......... ... .... ... ...." 24px
          / 30% 15px 90px 15px 1fr;
      }
      .thumbnail {
        grid-area: thumbnail;
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: $border-radius;
        &::before {
          content: "";
          width: 100%;
          padding-top: calc(3 / 4 * 100%); //高さの比率 ÷ 幅の比率 × 100
          display: block;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &:hover {
        background-color: $gray-100;
      }
      .date {
        grid-area: date;
        width: 66px;
        height: 66px;
        background-color: $primary;
        color: $white;
        position: relative;
        border-radius: $border-radius;
        @include media-breakpoint-up(sm) {
          width: 90px;
          height: 90px;
        }
        .month {
          // font-family: $font-family-en;
          font-weight: bold;
          font-size: 12px;
          line-height: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 55%;
          height: 55%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include media-breakpoint-up(sm) {
            font-size: 18px;
          }
        }
        .day {
          // font-family: $font-family-en;
          font-weight: bold;
          font-size: 24px;
          line-height: 1;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 75%;
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include media-breakpoint-up(sm) {
            font-size: 36px;
          }
        }
      }
      .cont {
        grid-area: cont;
        .title {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.25;
          padding: 7px 0;
          margin: 0;
          @include media-breakpoint-up(sm) {
            font-size: 30px;
            padding: 10px 0;
          }
        }
        .text {
          font-size: 14px;
          line-height: 1.5;
          margin: 0;
          @include media-breakpoint-up(sm) {
            font-size: 15px;
          }
        }
      }
    }
  }
}
