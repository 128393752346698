.imgOverlayHeader {
  width: 100%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  .bg {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius;
    &::before {
      content: "";
      width: 100%;
      padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      background: linear-gradient(to top, $body-color, transparent);
      opacity: 0.15;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75%;
    }
  }
  .cont {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding: 2rem;
    .articleHeader {
      margin: 0;
      * {
        color: $white;
      }
    }
  }
}
