.anchorLinks {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: solid 1px $border-color;
  gap: 20px;
  list-style: none;
  li {
    a {
      align-items: center;
      display: flex;
      gap: 5px;
      justify-content: flex-start;
      text-decoration: none;
      color: $body-color;
      font-size: 13px;
      .icon {
        transition: $transition-base;
      }
      &:hover {
        .icon {
          transform: translate(0, 2px);
        }
      }
    }
  }
}
