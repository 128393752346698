.topAbout {
  padding: 60px 0;
  position: relative;

  .bgWave {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .bgAnime {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  >div {
    position: relative;
    z-index: 2;
  }

  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }

  .topAboutTitle {
    position: relative;
    z-index: 2;
    font-size: 21px;
    color: $primary;
    line-height: 2;
    margin-bottom: 45px;

    br {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      text-align: center;
      font-size: 30px;
      letter-spacing: 0.2em;

      br {
        display: inline;
      }
    }
  }
}