#information-bar {
  width: 100%;
  background-color: $primary;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @include media-breakpoint-up(lg) {
    padding: 0 20px;
  }

  .tnav {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 280px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;

      li {
        flex: 1;
        border-right: 1px dotted $white;

        &:last-child {
          border-right: none;
        }

        a {
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: darken($primary, 10%);
          color: $white;
          text-decoration: none;
          font-size: 10px;
          font-weight: 600;
          line-height: 1;
          white-space: nowrap;

          @include media-breakpoint-up(sm) {
            height: 36px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .unav {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
      flex: 1;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        li {
          a {
            display: block;
            color: $white;
            text-decoration: none;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 1;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .push-topics {
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      max-width: 300px;

      .icon {
        color: $warning;
      }

      a {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: $white;
        text-decoration: none;
        font-size: 12px;
        font-weight: 300;
        line-height: 1;
      }
    }
  }
}