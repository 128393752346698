.aboutcont1 {
  position: relative;
  padding: 0 0 40px;
  margin-top: -50px;

  @include media-breakpoint-up(sm) {
    margin-top: -80px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.4;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include media-breakpoint-up(sm) {
        object-fit: contain;
      }
    }
  }

  .cont {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 60px 0;

    @include media-breakpoint-up(sm) {
      text-align: center;
      padding: 130px 0;
    }

  }

  .catchcopy {
    color: $primary;
    font-size: 21px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: 2.4px;
    margin-bottom: 24px;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.50);

    @include media-breakpoint-up(lg) {
      font-size: 30px;
      letter-spacing: 3px;
      margin-bottom: 36px;
    }
  }

  .leadcopy {
    color: $primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 1.4px;
    margin-bottom: 0;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.50);

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      letter-spacing: 1.8px;
      line-height: 45px;
    }
  }
}